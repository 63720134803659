import React from "react";

import * as styles from './notFound.module.scss';
import PrimaryButton from "../PrimaryButton/PrimaryButton";

const NotFound = ({ description, buttonLink, buttonLinkText }) => {
    return(
        <div className={styles.notFound}>
            <p>{description.description}</p>
            <PrimaryButton text={buttonLinkText} to={buttonLink}/>
        </div>
    )
}

export default NotFound