import React from "react";

import * as styles from './primaryButton.module.scss';
import { Link } from "gatsby";
import { useUrl, useLocalPath } from "../../../hooks";

const PrimaryButton = ({
    text,
    to
}) => {
    return(
        <div className={styles.button}>
            <Link to={useLocalPath(to)}>{text}</Link>
        </div>
    )
}

export default PrimaryButton